import React, { FC, useMemo } from 'react';
import { FormGrid, ISale, LabelValue } from '../../../shared';
import { ISupportCaseInfo } from '../../../shop/models';
import { useTranslation } from 'react-i18next';
import { FamilySituation } from '../../../shop/components/support-case-info/family-situation/family-situation.component';

interface Props {
    supportCase?: ISupportCaseInfo;
    sale?: ISale;
}

export const SaleDetailSupportCase: FC<Props> = ({ supportCase, sale }) => {
    const { t } = useTranslation();
    const primary = useMemo(() => supportCase?.supportees?.find((sup) => sup?.isPrimary), [supportCase]);

    return (
        <>
            {!supportCase ? (
                <LabelValue label={t('customer')} value={t('solidarityCustomer')} />
            ) : (
                <FormGrid xs={12} md={4}>
                    {sale && sale.cardCode && <LabelValue label={t('customerCard')} value={sale.cardCode} />}
                    <LabelValue
                        label={t('customer')}
                        value={primary && `${primary?.firstName || t('anonymous')} ${primary?.lastName || ''}`}
                    />
                    <FamilySituation supportCase={supportCase} />
                </FormGrid>
            )}
        </>
    );
};

import { Button } from '@mui/material';
import { GridCellParams, GridColDef } from '@mui/x-data-grid';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import {
    formatPercentage,
    Page,
    RowActions,
    SortOrder,
    TableComponent,
    useDeleteSupplier,
    useQueryParams,
    useSelectedOrganisation,
    useSuppliersList,
} from '../../../shared';

export const SuppliersPage: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { page, setPage, pageSize, setPageSize, sort, setSort } = useQueryParams({
        defaultSort: [{ field: 'name', sort: 'asc' }],
    });
    const { organisation } = useSelectedOrganisation();

    const { mutateAsync: deleteSupplier } = useDeleteSupplier();
    const { data: suppliers, isPending } = useSuppliersList({
        page,
        pageSize,
        sortBy: sort[0]?.field,
        sortOrder: sort[0]?.sort?.toUpperCase() as SortOrder,
        organisationId: organisation?.id,
    });

    const columns: GridColDef[] = [
        {
            field: 'name',
            headerName: t('name'),
            minWidth: 150,
            flex: 1,
            sortable: true,
        },
        {
            field: 'discountPercentage',
            headerName: t('discountPercentage'),
            minWidth: 150,
            flex: 1,
            sortable: false,
            valueFormatter: (value: number) => formatPercentage(value),
        },
        {
            field: 'taxIncluded',
            headerName: t('supplierTaxIncluded'),
            minWidth: 100,
            flex: 1,
            sortable: false,
            valueFormatter: (value: number) => (value ? t('yes') : t('no')),
        },
        {
            field: ' ',
            headerName: '',
            width: 120,
            align: 'right',
            sortable: false,
            renderCell: ({ row }: GridCellParams) => (
                <RowActions
                    onEdit={() => navigate(`${row?.id}/edit`)}
                    onDelete={() => deleteSupplier(row.id)}
                    deleteWarningTitle={t('deleteSupplier')}
                    deleteWarningText={t('deleteSupplierText')}
                />
            ),
        },
    ];

    return (
        <Page
            title={t('suppliers')}
            actions={[
                <Button component={Link} to="new" color="primary" variant="contained">
                    {t('newSupplier')}
                </Button>,
            ]}
        >
            <TableComponent
                rows={suppliers?.data || []}
                columns={columns}
                page={page}
                pageSize={pageSize}
                setPage={setPage}
                setPageSize={setPageSize}
                rowCount={suppliers?.pagination.size || 0}
                loading={isPending}
                sortModel={sort}
                onSortModelChange={setSort}
            />
        </Page>
    );
};

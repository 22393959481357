import { transparentize } from 'polished';
import { PaletteOptions } from '@mui/material/styles/createPalette';

export function getPalette(color: string, options?: PaletteOptions): PaletteOptions {
    return {
        primary: {
            main: color,
            shade: transparentize(0.85, color),
        },
        secondary: {
            main: color,
            shade: transparentize(0.85, color),
        },
        background: {
            default: '#FAFAFA',
            paper: '#FFF',
        },
        error: {
            main: '#AA1409',
            shade: '#AA14094D',
        },
        info: {
            main: '#0064B4',
            shade: transparentize(0.85, '#0064B4'),
        },
        ...options,
    };
}

import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
    formatDateString,
    IProduct,
    Page,
    RowActions,
    SortOrder,
    TableComponent,
    useQueryParams,
    useSelectedOrganisation,
} from '../../../shared';
import { Link, useNavigate } from 'react-router-dom';
import { useDeleteSaleQuotum, useSaleQuotaList } from '../../hooks';
import { GridCellParams, GridColDef } from '@mui/x-data-grid';
import { Button } from '@mui/material';

export const SaleQuotaPage: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { organisation } = useSelectedOrganisation();

    const { page, setPage, pageSize, setPageSize, sort, setSort } = useQueryParams({
        defaultSort: [{ field: 'createdDate', sort: 'desc' }],
    });

    const { mutateAsync: deleteSaleQuotum } = useDeleteSaleQuotum();
    const { data: saleQuota, isPending } = useSaleQuotaList({
        page,
        pageSize,
        sortBy: sort[0]?.field,
        sortOrder: sort[0]?.sort?.toUpperCase() as SortOrder,
        organisationId: organisation?.id,
    });

    const columns: GridColDef[] = [
        {
            field: 'createdDate',
            headerName: t('createdDate'),
            minWidth: 150,
            flex: 1,
            sortable: true,
            valueFormatter: (value: string) => formatDateString(value),
        },
        {
            field: 'product',
            headerName: t('product'),
            minWidth: 150,
            flex: 1,
            sortable: true,
            valueFormatter: (value: IProduct) =>
                value?.barcodes.length ? `${value?.name} (${value?.barcodes.join(', ')})` : `${value?.name}`,
        },
        {
            field: ' ',
            headerName: '',
            width: 120,
            align: 'right',
            sortable: false,
            renderCell: ({ row }: GridCellParams) => (
                <RowActions
                    onEdit={() => navigate(`${row?.id}/edit`)}
                    onDelete={() => deleteSaleQuotum(row.id)}
                    deleteWarningTitle={t('deleteSaleQuotumTitle')}
                    deleteWarningText={t('deleteSaleQuotumText')}
                />
            ),
        },
    ];

    return (
        <Page
            title={t('saleQuota')}
            actions={[
                <Button component={Link} to="new" color="primary" variant="contained">
                    {t('newSaleQuotum')}
                </Button>,
            ]}
        >
            <TableComponent
                rows={saleQuota?.data || []}
                columns={columns}
                page={page}
                pageSize={pageSize}
                setPage={setPage}
                setPageSize={setPageSize}
                rowCount={saleQuota?.pagination.size || 0}
                loading={isPending}
                sortModel={sort}
                onSortModelChange={setSort}
            />
        </Page>
    );
};

import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { ListModel, ListQueryParam, QueryKeys } from '../../shared';
import { supportCaseClient } from '../clients';
import { ISupportCaseInfo } from '../models';
import { ISupportCase } from '../models/support-case.model';

const client = supportCaseClient;
const listKey = QueryKeys.SUPPORT_CASES;
const itemKey = QueryKeys.SUPPORT_CASE_INFO;

export function useSupportCases(params: ListQueryParam, enabled: boolean, organisation?: string) {
    return useQuery<ListModel<ISupportCase>, AxiosError>({
        queryKey: [listKey, params, { organisation: organisation }],
        queryFn: () => client.getSupportCases(params, organisation),
        enabled: enabled,
    });
}

export function useSupportCaseInfo(supportCaseId?: string, cardCode?: string, organisation?: string) {
    return useQuery<ISupportCaseInfo, AxiosError>({
        queryKey: [itemKey, { supportCaseId, cardCode, organisation }],
        queryFn: () => client.getInfo(supportCaseId, cardCode, organisation),
        enabled: (!!supportCaseId || !!cardCode) && !!organisation,
        retry: false,
    });
}

import { ListModel, ListQueryParam, shopApi, supportApi } from '../../shared';
import { ISupportCaseInfo } from '../models';
import { ISupportCase } from '../models/support-case.model';

class SupportCaseClient {
    public async getSupportCases(params: ListQueryParam, organisation?: string): Promise<ListModel<ISupportCase>> {
        const { data } = await supportApi.get('/support-cases', {
            params: { ...params, organisationId: organisation },
        });
        return data;
    }

    public async getInfo(supportCaseId?: string, cardCode?: string, organisation?: string): Promise<ISupportCaseInfo> {
        const { data } = await shopApi.get<Omit<ISupportCaseInfo, 'cardCode'>>('/support-cases/info', {
            params: { supportCaseId, cardCode, organisation },
        });
        return { ...data, cardCode: cardCode as string };
    }
}

export const supportCaseClient = new SupportCaseClient();

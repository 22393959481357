import { TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import React, { ComponentProps, FC } from 'react';
import { Control, Controller, FieldError } from 'react-hook-form';
import { DateFormat } from '../../enums';

type Props = ComponentProps<typeof TextField> & {
    name: string;
    control?: Control<any>;
};

export const ControlledDatePicker: FC<Props> = ({ name, control, disabled, ...textFieldProps }) => {
    return (
        <Controller
            name={name}
            control={control}
            defaultValue=""
            render={({ field: { value, onChange }, fieldState: { error } }) => (
                <DatePicker
                    slotProps={{
                        textField: {
                            ...textFieldProps,
                            id: `${name}-field`,
                            helperText: (error as FieldError | undefined)?.message,
                            error: !!error,
                        },
                        inputAdornment: { position: 'start' },
                    }}
                    label={textFieldProps.label}
                    value={value ? new Date(value) : null}
                    onChange={onChange}
                    format={DateFormat.DD_MM_YYYY}
                    disabled={disabled}
                    views={['year', 'month', 'day']}
                />
            )}
        />
    );
};

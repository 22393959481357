import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Card, CardActions, CardContent, Grid, InputAdornment } from '@mui/material';
import React, { FC, Fragment, useCallback, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import {
    ControlledCheckbox,
    ControlledInput,
    ControlledNumberInput,
    IParams,
    ISupplierForm,
    Page,
    RemoveModal,
    Section,
    useDeleteSupplier,
    useSaveSupplier,
    useSelectedOrganisation,
    useSupplier,
} from '../../../shared';
import { useSupplierSchema } from '../../validators';

export const SupplierEditPage: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { id } = useParams<keyof IParams>() as IParams;
    const { organisation } = useSelectedOrganisation();

    const { data: supplier, isFetching } = useSupplier(id);
    const { mutateAsync: saveSupplier, isPending: isSaving } = useSaveSupplier();
    const { mutateAsync: deleteSupplier } = useDeleteSupplier();

    const form = useForm<ISupplierForm>({
        mode: 'all',
        resolver: yupResolver(useSupplierSchema()),
    });

    useEffect(() => {
        if (supplier) {
            form.reset(supplier);
        }
    }, [supplier, form]);

    const onSubmit = useCallback(
        async (item: ISupplierForm) => {
            if (organisation?.id) {
                await saveSupplier({
                    id,
                    item: {
                        ...item,
                        organisationId: organisation?.id,
                    },
                });
                navigate('/admin/suppliers');
            }
        },
        [id, navigate, organisation?.id, saveSupplier],
    );

    const onDelete = useCallback(async () => {
        await deleteSupplier(id);
        navigate('/admin/suppliers');
    }, [deleteSupplier, navigate, id]);

    const actions = useMemo(
        () => [
            <Button
                key={'submit'}
                variant="contained"
                color="primary"
                onClick={form.handleSubmit(onSubmit)}
                disabled={isFetching}
            >
                {t('save')}
            </Button>,
            <Fragment key="delete">
                {id && (
                    <RemoveModal
                        handleDelete={onDelete}
                        button={
                            <Button color="primary" variant="outlined">
                                {t('delete')}
                            </Button>
                        }
                        title={t('deleteSupplier')}
                        text={t('deleteSupplierText')}
                    />
                )}
            </Fragment>,
            <Button key={'cancel'} onClick={() => navigate('/admin/suppliers')} color="secondary">
                {t('cancel')}
            </Button>,
        ],
        [form, onSubmit, isFetching, t, id, onDelete, navigate],
    );
    const reversedActions = useMemo(() => [...actions].reverse(), [actions]);

    return (
        <Page
            onBack={() => navigate(-1)}
            title={supplier?.id ? t('updateSupplier') : t('newSupplier')}
            loading={isSaving}
            actions={reversedActions}
        >
            <FormProvider {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)}>
                    <Card variant="outlined">
                        <CardContent>
                            <Section>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={4}>
                                        <ControlledInput name="name" label={t('name')} required />
                                        <ControlledNumberInput
                                            name="discountPercentage"
                                            label={t('discountPercentage')}
                                            slotProps={{
                                                input: {
                                                    endAdornment: <InputAdornment position="end" children="%" />,
                                                },
                                            }}
                                        />
                                        <ControlledCheckbox
                                            control={form.control}
                                            name="taxIncluded"
                                            label={t('supplierTaxIncluded')}
                                        />
                                    </Grid>
                                </Grid>
                            </Section>
                        </CardContent>
                        <CardActions sx={{ backgroundColor: 'background.default' }} children={actions} />
                    </Card>
                </form>
            </FormProvider>
        </Page>
    );
};

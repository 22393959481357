import { Box, Typography } from '@mui/material';
import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';

export const AppTitle: FC = () => {
    const navigate = useNavigate();

    return (
        <Box sx={{ display: { xs: 'none', xl: 'block' } }}>
            <Typography variant="h1" sx={{ cursor: 'pointer' }} onClick={() => navigate('')}>
                <Box
                    sx={{
                        marginRight: '12px',
                        height: window.carity.environment.theme.name === 'antwerp' ? 'auto' : 40,
                    }}
                >
                    <img src={window.carity.environment.theme.logo} style={{ height: '100%' }} alt="Carity" />
                </Box>
                carity
                <strong>shop</strong>
            </Typography>
        </Box>
    );
};

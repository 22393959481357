import scanner from 'onscan.js';
import React, { FC, useEffect } from 'react';
import { Page, useCurrentRegisterSession, useSelectedOrganisation, useShop } from '../../../shared';
import { Register, StartNewSale, StartRegisterSession } from '../../components';
import { useCurrentSale, useOnScan, useSupportCaseInfo } from '../../hooks';
import { CustomerType } from '../../../shared/enums/customer-type.enum';

export const ShopPage: FC = () => {
    const { organisation } = useSelectedOrganisation();
    const { setShowWarningNoSupportCase, customer, setCustomer } = useShop();

    const { data: currentSale } = useCurrentSale(organisation?.id);
    const { data: currentRegisterSession, isPending: currentRegisterSessionIsPending } = useCurrentRegisterSession(
        organisation?.id,
    );
    const {
        data: supportCase,
        isPending,
        isError: supportCaseFailedToLoad,
    } = useSupportCaseInfo(customer?.id, customer?.cardCode, organisation?.id);

    useEffect(() => {
        setShowWarningNoSupportCase(supportCaseFailedToLoad);
    }, [supportCaseFailedToLoad, setShowWarningNoSupportCase]);

    useEffect(() => {
        if (currentSale) {
            if (currentSale.supportCaseId)
                setCustomer({
                    id: currentSale.supportCaseId,
                    cardCode: currentSale.cardCode,
                    type: CustomerType.SOCIAL,
                });
        }
    }, [currentSale, setCustomer]);

    useEffect(() => {
        if (supportCase) {
            setCustomer({ id: supportCase.supportCaseId, cardCode: supportCase.cardCode, type: CustomerType.SOCIAL });
        }
    }, [supportCase, setCustomer]);

    const onScan = useOnScan(supportCase);
    useEffect(() => {
        scanner.attachTo(document, { onScan, minLength: 4 });
        return () => scanner.detachFrom(document);
    }, [onScan]);

    const pageLoading = (isPending && !!customer?.id && !!organisation) || currentRegisterSessionIsPending;
    const hasSale = customer?.type !== undefined || currentSale;

    return (
        <Page loading={pageLoading}>
            {!currentRegisterSession ? (
                <StartRegisterSession />
            ) : (
                <>{hasSale ? <Register supportCase={supportCase} currentSale={currentSale} /> : <StartNewSale />}</>
            )}
        </Page>
    );
};

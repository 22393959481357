import { useQuery } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { QueryKeys } from '../../shared';
import { IReport } from '../models';

async function getReport(id: string): Promise<IReport> {
    const { data } = await axios.get(`/reporting/reports/${id}`);
    return data;
}

export const useReport = (id: string) => {
    return useQuery<IReport, AxiosError>({ queryKey: [QueryKeys.REPORT, id], queryFn: () => getReport(id) });
};

import { Button } from '@mui/material';
import { GridCellParams, GridColDef } from '@mui/x-data-grid';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import {
    Page,
    PermissionKeys,
    RowActions,
    SortOrder,
    TableComponent,
    useCategoriesList,
    useDeleteCategory,
    useHasPermission,
    useQueryParams,
} from '../../../shared';

export const CategoriesPage: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { hasPermission } = useHasPermission();
    const hasWritePermission = hasPermission(PermissionKeys.CATEGORIES_WRITE);

    const { page, setPage, pageSize, setPageSize, sort, setSort } = useQueryParams({
        defaultSort: [{ field: 'name', sort: 'asc' }],
    });

    const { mutateAsync: deleteCategory } = useDeleteCategory();
    const { data: categories, isPending } = useCategoriesList({
        page,
        pageSize,
        sortBy: sort[0]?.field,
        sortOrder: sort[0]?.sort?.toUpperCase() as SortOrder,
    });

    const columns: GridColDef[] = [
        {
            field: 'name',
            headerName: t('name'),
            minWidth: 150,
            flex: 1,
            sortable: true,
        },
        {
            field: 'tax',
            headerName: t('productTax'),
            minWidth: 150,
            flex: 1,
            sortable: false,
        },
        {
            field: ' ',
            headerName: '',
            width: 120,
            align: 'right',
            sortable: false,
            renderCell: ({ row }: GridCellParams) => (
                <>
                    {hasWritePermission && (
                        <RowActions
                            onEdit={() => navigate(`${row?.id}/edit`)}
                            onDelete={() => deleteCategory(row.id)}
                            deleteWarningTitle={t('deleteCategory')}
                            deleteWarningText={t('deleteCategoryText')}
                        />
                    )}
                </>
            ),
        },
    ];

    return (
        <Page
            title={t('categories')}
            actions={[
                <>
                    {hasWritePermission && (
                        <Button component={Link} to="new" color="primary" variant="contained">
                            {t('newCategory')}
                        </Button>
                    )}
                </>,
            ]}
        >
            <TableComponent
                rows={categories?.data || []}
                columns={columns}
                page={page}
                pageSize={pageSize}
                setPage={setPage}
                setPageSize={setPageSize}
                rowCount={categories?.pagination.size || 0}
                loading={isPending}
                sortModel={sort}
                onSortModelChange={setSort}
            />
        </Page>
    );
};

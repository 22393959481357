import { createTheme, Theme } from '@mui/material';
import { ITheme } from '../../shared';
import { getComponents } from '../utils/components.util';
import { getTypography } from '../utils/typography.util';
import { getPalette } from '../utils/palette.util';

export const createBaseTheme = (theme: ITheme): Theme => {
    const palette = getPalette(theme.color);
    const baseTheme = createTheme({ palette });
    const typography = getTypography(baseTheme);
    const components = getComponents(baseTheme);

    return createTheme(baseTheme, { typography, components });
};

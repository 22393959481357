import { Button, IconButton } from '@mui/material';
import { GridCellParams, GridColDef } from '@mui/x-data-grid';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
    formatDateString,
    ISupplier,
    Page,
    PermissionKeys,
    RemoveModal,
    SortOrder,
    TableComponent,
    useHasPermission,
    useQueryParams,
    useSelectedOrganisation,
} from '../../../shared';
import { useDeletePurchase, usePurchasesList } from '../../hooks';
import { DeleteOutlined, EditOutlined, Search } from '@mui/icons-material';
import { lowerCase } from 'lodash';
import { IPurchaseStatus } from '../../enums';

export const PurchasesPage: FC = () => {
    const { t } = useTranslation();
    const { organisation } = useSelectedOrganisation();
    const { hasPermission } = useHasPermission();
    const hasPurchaseWritePermission = hasPermission(PermissionKeys.PURCHASES_WRITE);
    const hasShoppingListWritePermission = hasPermission(PermissionKeys.SHOPPING_LISTS_WRITE);

    const { page, setPage, pageSize, setPageSize, sort, setSort } = useQueryParams({
        defaultSort: [{ field: 'date', sort: 'desc' }],
    });

    const { mutateAsync: deletePurchase } = useDeletePurchase();
    const { data: purchases, isPending } = usePurchasesList({
        page,
        pageSize,
        sortBy: sort[0]?.field,
        sortOrder: sort[0]?.sort?.toUpperCase() as SortOrder,
        organisationId: organisation?.id,
    });

    const columns: GridColDef[] = [
        {
            field: 'invoiceNumber',
            headerName: t('invoiceNumber'),
            minWidth: 150,
            flex: 1,
            sortable: true,
        },
        {
            field: 'supplier',
            headerName: t('supplier'),
            minWidth: 150,
            flex: 1,
            sortable: false,
            valueFormatter: (value: ISupplier) => value?.name,
        },
        {
            field: 'date',
            headerName: t('date'),
            minWidth: 150,
            flex: 1,
            sortable: true,
            valueFormatter: (value: string) => formatDateString(value),
        },
        {
            field: 'gift',
            headerName: t('gift'),
            minWidth: 150,
            flex: 1,
            sortable: true,
            valueFormatter: (value: boolean) => (value ? t('yes') : t('no')),
        },
        {
            field: 'status',
            headerName: t('status'),
            minWidth: 150,
            flex: 1,
            sortable: true,
            valueFormatter: (value: IPurchaseStatus) => t(lowerCase(value)),
        },
        {
            field: ' ',
            headerName: '',
            width: 150,
            align: 'right',
            sortable: false,
            renderCell: ({ row }: GridCellParams) => {
                const isCompleted = row.status === IPurchaseStatus.COMPLETED;
                return (
                    <>
                        {hasPurchaseWritePermission && (
                            <>
                                <IconButton component={Link} to={`${row.id}/detail`} disabled={!isCompleted}>
                                    <Search />
                                </IconButton>
                                <IconButton component={Link} to={row?.id} disabled={isCompleted}>
                                    <EditOutlined />
                                </IconButton>
                                <RemoveModal
                                    handleDelete={() => deletePurchase(row.id)}
                                    button={
                                        <IconButton disabled={isCompleted}>
                                            <DeleteOutlined />
                                        </IconButton>
                                    }
                                    title={t('deletePurchase')}
                                    text={t('deletePurchaseText')}
                                />
                            </>
                        )}
                    </>
                );
            },
        },
    ];

    return (
        <Page
            title={t('purchases')}
            actions={[
                hasShoppingListWritePermission && (
                    <Button component={Link} to="shopping-lists" color="primary" variant="outlined">
                        {t('createShoppingList')}
                    </Button>
                ),
                <Button component={Link} to="new" color="primary" variant="contained">
                    {t('newPurchase')}
                </Button>,
            ]}
        >
            <TableComponent
                rows={purchases?.data || []}
                columns={columns}
                page={page}
                pageSize={pageSize}
                setPage={setPage}
                setPageSize={setPageSize}
                rowCount={purchases?.pagination.size || 0}
                loading={isPending}
                sortModel={sort}
                onSortModelChange={setSort}
            />
        </Page>
    );
};

import { Typography } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { IInfoSupportee } from '../../../models';
import { Gender } from '../../../enums/gender.enum';

interface Props {
    supportees: IInfoSupportee[];
}

export const FamilySituationInfo: FC<Props> = ({ supportees }) => {
    const { t } = useTranslation();

    const formatGender = (gender?: Gender) => {
        if (!!gender) return t(gender);
        else return t(Gender.Other);
    };

    const formatAge = (age?: number) => `${age != null ? t('yearsOld', { age }) : ''}`;

    return (
        <>
            {supportees.map((supportee, index) => (
                <Typography component="span" variant="body2" key={index}>
                    {`${formatGender(supportee.gender)} ${formatAge(supportee.age)}`}
                    {index + 1 !== supportees.length && ', '}
                </Typography>
            ))}
        </>
    );
};

import React, { FC } from 'react';
import { Navigate, Route, Routes as RRRoutes } from 'react-router-dom';
import { reportsRoutes } from '../../../reports';
import { IRouteConfig, IRouteConfigWithRedirect, ProtectedRoute } from '../../../shared';
import { PageNotFound } from '../../pages';
import { shopRoutes } from '../../../shop';
import { adminRoutes } from '../../../admin';
import { stockRoutes } from '../../../stock';
import { historyRoutes } from '../../../history';

export const ROUTES: IRouteConfig[] = [
    { path: '/', redirect: '/shop' },
    ...shopRoutes,
    ...stockRoutes,
    ...adminRoutes,
    ...historyRoutes,
    ...reportsRoutes,
];

function isRedirect(config: IRouteConfig): config is IRouteConfigWithRedirect {
    return !!(config as IRouteConfigWithRedirect).redirect;
}

export const Routes: FC = () => {
    return (
        <RRRoutes>
            {ROUTES.map((config, i) =>
                isRedirect(config) ? (
                    <Route path={config.path} key={i} element={<Navigate to={config.redirect} />} />
                ) : (
                    <Route
                        key={i}
                        path={config.path}
                        element={
                            <ProtectedRoute requiredPermission={config.requiredPermissions}>
                                <config.component />
                            </ProtectedRoute>
                        }
                    />
                ),
            )}
            <Route path="*" element={<PageNotFound />} />
        </RRRoutes>
    );
};

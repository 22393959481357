import { Autocomplete, TextField } from '@mui/material';
import { IProduct } from '../../models';
import { createFilterOptions } from '@mui/material/Autocomplete/Autocomplete';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useDebouncedState, useProductsList } from '../../hooks';
import { useTranslation } from 'react-i18next';
import { useSelectedOrganisation } from '../../contexts';
import scanner from 'onscan.js';
import { FieldError } from 'react-hook-form';

interface Props {
    selectedProduct: IProduct | null | undefined;
    setSelectedProduct: (product: IProduct | null) => void;
    error?: FieldError;
    useOnScan?: boolean;
}

export const AutocompleteProducts: FC<Props> = ({ selectedProduct, setSelectedProduct, error, useOnScan = true }) => {
    const { t } = useTranslation();
    const { organisation } = useSelectedOrganisation();

    const [open, setOpen] = useState(false);
    const [debouncedSearch, , setSearch] = useDebouncedState('');
    const { data: products, isPending: isPendingProducts } = useProductsList(
        {
            page: 1,
            pageSize: 10,
            organisationId: organisation?.id,
            search: debouncedSearch,
        },
        { enabled: !!debouncedSearch },
    );

    useEffect(() => {
        window.document.getElementById('product')?.focus();
    }, []);

    const ref: React.Ref<any> = useRef();

    const onScan = useCallback(
        (value: string) => {
            setSearch(value);
            ref.current?.focus();
        },
        [setSearch],
    );

    useEffect(() => {
        if (useOnScan) {
            ref.current?.focus();
            scanner.attachTo(document, { onScan, minLength: 4 });
            return () => scanner.detachFrom(document);
        }
    }, [onScan, useOnScan]);

    return (
        <Autocomplete
            id="product"
            open={open}
            onClose={() => setOpen(false)}
            value={selectedProduct || null}
            renderInput={(params) => (
                <TextField
                    {...params}
                    name="product"
                    placeholder={t('searchProduct')}
                    inputRef={ref}
                    error={!!error}
                    helperText={error?.message}
                />
            )}
            onInputChange={(e, value) => {
                if (value.length === 0) setOpen(false);
                else setOpen(true);
                setSearch(value);
            }}
            onChange={(e, value: IProduct | null) => setSelectedProduct(value)}
            options={products?.data || []}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            getOptionLabel={(product) =>
                `${product.name}${product.barcodes?.length ? ` (${product.barcodes?.join(', ')})` : ''}` || ''
            }
            renderOption={(props, product) => {
                return (
                    <li {...props} key={product.id}>
                        {product.name} {product.barcodes?.length ? `(${product.barcodes?.join(', ')})` : ''}
                    </li>
                );
            }}
            noOptionsText={debouncedSearch ? t('noProductsFound') : t('searchProducts')}
            filterOptions={createFilterOptions({ ignoreCase: true })}
            sx={{ '.MuiFormControl-root': { mb: 0 } }}
            loading={isPendingProducts}
            loadingText={debouncedSearch ? t('loadingSearch') : t('startSearch')}
            autoHighlight
            autoSelect
            openOnFocus
        />
    );
};

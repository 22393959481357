import { Delete, Edit, ShoppingBasket } from '@mui/icons-material';
import { Box, Button, ListItemText, Stack, Typography } from '@mui/material';
import { DataGrid, GridCellParams, GridColDef } from '@mui/x-data-grid';
import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
    formatAmount,
    formatPrice,
    ISale,
    ISaleItem,
    isValueDefined,
    MeasurementUnit,
    RemoveModal,
    useShop,
    Widget,
} from '../../../shared';
import { useRemoveSaleItem, useUpdateSaleItem } from '../../hooks';
import { ISupportCaseInfo } from '../../models';
import { SaveProduct } from '../save-product/save-product.component';

interface Props {
    currentSale?: ISale | void;
    supportCase?: ISupportCaseInfo;
}

export const CurrentSaleShoppingList: FC<Props> = ({ currentSale, supportCase }) => {
    const { t } = useTranslation();
    const { mutateAsync: removeSaleItem } = useRemoveSaleItem();
    const { mutateAsync: updateSaleItem } = useUpdateSaleItem();
    const { selectedSaleItem, setSelectedSaleItem } = useShop();

    const onRemoveSaleItem = async (saleItemId: string) => {
        if (currentSale) {
            await removeSaleItem(saleItemId);
        }
    };

    const onUpdateSaleItem = async (amount: number, price: number, item?: ISaleItem): Promise<void> => {
        const saleItem = item || selectedSaleItem;
        if (currentSale && saleItem) {
            price = saleItem.measurementUnit === MeasurementUnit.GRAM ? price / 1000 : price;
            await updateSaleItem({
                saleItemId: saleItem.id,
                saleItem: {
                    amount: saleItem.measurementUnit === MeasurementUnit.GRAM ? amount * 1000 : amount,
                    alteredProductPrice: price !== saleItem.productPrice ? price : null,
                    amountOfPeople: supportCase?.supportees.length || undefined,
                },
            });
        }
        setSelectedSaleItem(undefined);
    };

    const selectedSaleItemPrice = useMemo(() => {
        const price = isValueDefined(selectedSaleItem?.alteredProductPrice)
            ? selectedSaleItem?.alteredProductPrice
            : selectedSaleItem?.productPrice;

        return price && selectedSaleItem?.measurementUnit === MeasurementUnit.GRAM ? price * 1000 : price;
    }, [selectedSaleItem?.alteredProductPrice, selectedSaleItem?.measurementUnit, selectedSaleItem?.productPrice]);

    const columns: GridColDef[] = [
        {
            field: 'productName',
            headerName: t('name'),
            minWidth: 50,
            flex: 0.5,
            sortable: true,
            renderCell: ({ row }: GridCellParams) => (
                <ListItemText
                    primaryTypographyProps={{ mt: row.quotumExceeded ? 0 : 1.5 }}
                    secondaryTypographyProps={{ color: 'error', fontSize: '12px' }}
                    primary={row.productName}
                    secondary={row.quotumExceeded && t('saleQuotumExceededTitle')}
                />
            ),
        },
        {
            field: 'amount',
            headerName: t('totalPurchasePrice'),
            minWidth: 50,
            flex: 0.2,
            align: 'center',
            sortable: false,
            valueFormatter: (value: number, row) => formatAmount(row.measurementUnit, value, t),
        },
        {
            field: 'price',
            headerName: t('price'),
            minWidth: 50,
            flex: 0.2,
            align: 'center',
            sortable: false,
            renderCell: ({ row }: GridCellParams) => {
                const price = isValueDefined(row.alteredProductPrice) ? row.alteredProductPrice : row.productPrice;
                return formatPrice(price * row.amount);
            },
        },
        {
            field: ' ',
            headerName: '',
            width: 100,
            flex: 0.3,
            align: 'right',
            sortable: false,
            renderCell: ({ row }: GridCellParams) => (
                <Box sx={{ '.MuiButton-root': { minWidth: 'fit-content', height: 'fit-content', p: 1 } }}>
                    {row.amount > 0 && (
                        <Button onClick={() => setSelectedSaleItem(row)} variant="contained">
                            <Edit sx={{ fontSize: '20px' }} />
                        </Button>
                    )}

                    <RemoveModal
                        handleDelete={() => onRemoveSaleItem(row.id)}
                        button={
                            <Button color="error" variant="contained" sx={{ ml: 1 }}>
                                <Delete sx={{ fontSize: '20px' }} />
                            </Button>
                        }
                        title={t('removeSaleItem')}
                        text={t('removeSaleItemWarning')}
                    />
                </Box>
            ),
        },
    ];

    return (
        <>
            <Widget
                title={t('shoppingCart')}
                icon={<ShoppingBasket color="primary" />}
                hasTable
                footer={
                    <Box width="100%" px={2}>
                        <Stack direction="row" justifyContent="space-between" sx={{ h2: { mb: 0 } }}>
                            <Typography variant="h2">{`${t('toPay')} `}</Typography>
                            <Typography variant="h2">{formatPrice(currentSale?.totalPrice)}</Typography>
                        </Stack>
                    </Box>
                }
            >
                <DataGrid
                    rows={currentSale?.saleItems || []}
                    columns={columns}
                    hideFooter
                    sx={{ fontSize: '16px', '.MuiDataGrid-overlay, .MuiDataGrid-topContainer': { display: 'none' } }}
                />

                {selectedSaleItem && (
                    <SaveProduct
                        open={!!selectedSaleItem}
                        onClose={() => setSelectedSaleItem(undefined)}
                        onSubmit={onUpdateSaleItem}
                        product={{
                            price: selectedSaleItemPrice,
                            measurementUnit: selectedSaleItem.measurementUnit,
                            hasPrice: true,
                        }}
                        currentAmount={selectedSaleItem.amount}
                        isNewSaleItem={false}
                    />
                )}
            </Widget>
        </>
    );
};

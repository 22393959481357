import { MeasurementUnit } from '../enums';

export const mapPriceFromApi = (measurement?: MeasurementUnit, value?: number | null) => {
    return measurement && value ? (measurement === MeasurementUnit.GRAM ? value * 1000 : value) : undefined;
};

export const mapPriceToApi = (measurement?: MeasurementUnit, value?: number | null) => {
    return measurement && value ? (measurement === MeasurementUnit.GRAM ? value / 1000 : value) : undefined;
};

export const mapAmountFromApi = mapPriceToApi;
export const mapAmountToApi = mapPriceFromApi;

export default {
    nav: {
        title: 'Carity Shop',
        shop: 'Winkel',
        stock: 'Stockbeheer',
        admin: 'Admin',
        history: 'Historiek',
        reports: 'Rapporten',
    },

    home: {
        title: 'Welkom bij Carity Shop',
    },
};

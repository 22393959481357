import { Box, Card, Tab, Tabs } from '@mui/material';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelectedOrganisation, useShop, useShopCategoriesList } from '../../../shared';
import { useCreateSaleItem, useSupportCaseInfo } from '../../hooks';
import { PriceProduct } from '../price-product/price-product.component';
import { ProductImages } from '../product-images/product-images.component';
import { SaveProduct } from '../save-product/save-product.component';
import { SearchProduct } from '../search-product/search-product.component';
import { ReturnProduct } from '../return-product/return-product.component';
import { saleItemFromFormMapper } from '../../mappers';

type TabValue = {
    id: string;
    name: string;
};

export const Products: FC = () => {
    const { t } = useTranslation();
    const { organisation } = useSelectedOrganisation();
    const { customer, selectedProduct, setSelectedProduct, returnTabActive, setReturnTabActive } = useShop();
    const { data: supportCase } = useSupportCaseInfo(customer?.id, customer?.cardCode, organisation?.id);
    const { mutateAsync: saveSaleItem } = useCreateSaleItem();
    const { data: shopCategories } = useShopCategoriesList({
        pageSize: 100,
        organisationId: organisation?.id,
        show: true,
    });

    const tabsValues: TabValue[] = useMemo(
        () => [
            ...(shopCategories?.data || []),
            { id: 'search', name: t('search') } as TabValue,
            { id: 'price', name: t('exceptionalProduct') } as TabValue,
            { id: 'return', name: t('return') } as TabValue,
        ],
        [shopCategories?.data, t],
    );

    const [selectedTab, setSelectedTab] = useState<string>(tabsValues[0]?.id);

    useEffect(() => {
        setSelectedTab(tabsValues[0]?.id);
    }, [tabsValues]);

    useEffect(() => {
        setReturnTabActive(selectedTab === 'return');
    }, [selectedTab, setReturnTabActive]);

    const onSubmit = async (amount: number, price: number): Promise<void> => {
        if (selectedProduct && organisation && (selectedProduct?.id || selectedProduct?.barcode)) {
            await saveSaleItem(saleItemFromFormMapper(selectedProduct, organisation.id, amount, price, supportCase));
        }
        setSelectedProduct(undefined);
    };

    return (
        <>
            <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    {selectedTab && (
                        <Tabs
                            indicatorColor="primary"
                            textColor="primary"
                            value={selectedTab}
                            variant="scrollable"
                            scrollButtons
                            allowScrollButtonsMobile
                            sx={{ width: '100%' }}
                        >
                            {tabsValues?.map((tab) => (
                                <Tab
                                    label={tab.name}
                                    value={tab.id}
                                    key={tab.id}
                                    onClick={() => {
                                        setSelectedTab(tab.id);
                                    }}
                                />
                            ))}
                        </Tabs>
                    )}
                </Box>
                <Box p={2} sx={{ overflow: 'auto' }}>
                    {selectedTab !== 'barcode' &&
                        selectedTab !== 'search' &&
                        selectedTab !== 'price' &&
                        selectedTab !== 'return' && <ProductImages shopCategoryId={selectedTab} />}
                    {selectedTab === 'search' && <SearchProduct />}
                    {selectedTab === 'price' && <PriceProduct supportCase={supportCase} />}
                    {selectedTab === 'return' && <ReturnProduct supportCase={supportCase} />}
                </Box>
            </Card>

            {selectedProduct && !returnTabActive && (
                <SaveProduct
                    open={!!selectedProduct}
                    onClose={() => setSelectedProduct(undefined)}
                    onSubmit={onSubmit}
                    product={selectedProduct}
                    currentAmount={selectedProduct.amount}
                />
            )}
        </>
    );
};

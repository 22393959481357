import { Box, Typography } from '@mui/material';
import React, { FC } from 'react';
import { ControlledNumberInput, FormGrid } from '../../../shared';
import { useTranslation } from 'react-i18next';

export const RegisterSessionCashInputs: FC = () => {
    const { t } = useTranslation();

    return (
        <FormGrid xs={12}>
            <Box sx={{ width: '100%', flexGrow: 1 }}>
                <Typography variant="subtitle1">{t('bills')}</Typography>
                <FormGrid sm={12} md={6}>
                    <Box>
                        <ControlledNumberInput
                            name="amount100Euro"
                            label={t('amountBills', { amount: 100 })}
                            slotProps={{ htmlInput: { readOnly: true } }}
                            decimalScale={0}
                        />
                        <ControlledNumberInput
                            name="amount50Euro"
                            label={t('amountBills', { amount: 50 })}
                            slotProps={{ htmlInput: { readOnly: true } }}
                            decimalScale={0}
                        />
                        <ControlledNumberInput
                            name="amount20Euro"
                            label={t('amountBills', { amount: 20 })}
                            slotProps={{ htmlInput: { readOnly: true } }}
                            decimalScale={0}
                        />
                    </Box>
                    <Box>
                        <ControlledNumberInput
                            name="amount10Euro"
                            label={t('amountBills', { amount: 10 })}
                            slotProps={{ htmlInput: { readOnly: true } }}
                            decimalScale={0}
                        />
                        <ControlledNumberInput
                            name="amount5Euro"
                            label={t('amountBills', { amount: 5 })}
                            slotProps={{ htmlInput: { readOnly: true } }}
                            decimalScale={0}
                        />
                    </Box>
                </FormGrid>
            </Box>

            <Box sx={{ width: '100%', flexGrow: 1 }}>
                <Typography variant="subtitle1">{t('coins')}</Typography>
                <FormGrid sm={12} md={6}>
                    <Box>
                        <ControlledNumberInput
                            name="amount2Euro"
                            label={t('amountCoins', { amount: 2 })}
                            slotProps={{ htmlInput: { readOnly: true } }}
                            decimalScale={0}
                        />
                        <ControlledNumberInput
                            name="amount1Euro"
                            label={t('amountCoins', { amount: 1 })}
                            slotProps={{ htmlInput: { readOnly: true } }}
                            decimalScale={0}
                        />
                        <ControlledNumberInput
                            name="amount50Cents"
                            label={t('amountCoins', { amount: '0,50' })}
                            slotProps={{ htmlInput: { readOnly: true } }}
                            decimalScale={0}
                        />
                        <ControlledNumberInput
                            name="amount20Cents"
                            label={t('amountCoins', { amount: '0,20' })}
                            slotProps={{ htmlInput: { readOnly: true } }}
                            decimalScale={0}
                        />
                    </Box>

                    <Box>
                        <ControlledNumberInput
                            name="amount10Cents"
                            label={t('amountCoins', { amount: '0,10' })}
                            slotProps={{ htmlInput: { readOnly: true } }}
                            decimalScale={0}
                        />
                        <ControlledNumberInput
                            name="amount5Cents"
                            label={t('amountCoins', { amount: '0,05' })}
                            slotProps={{ htmlInput: { readOnly: true } }}
                            decimalScale={0}
                        />
                        <ControlledNumberInput
                            name="amount2Cents"
                            label={t('amountCoins', { amount: '0,02' })}
                            slotProps={{ htmlInput: { readOnly: true } }}
                            decimalScale={0}
                        />
                        <ControlledNumberInput
                            name="amount1Cents"
                            label={t('amountCoins', { amount: '0,01' })}
                            slotProps={{ htmlInput: { readOnly: true } }}
                            decimalScale={0}
                        />
                    </Box>
                </FormGrid>
            </Box>
        </FormGrid>
    );
};

import { DataGrid, GridFeatureMode, GridSortItem, GridSortModel } from '@mui/x-data-grid';
import React, { ComponentProps, Dispatch } from 'react';
import { CustomTableFooter } from '../table-footer/table-footer.component';
import { NoRowsOverlay } from '../no-rows-overlay/no-rows-overlay.component';

interface Props extends Omit<ComponentProps<typeof DataGrid>, 'pagination'> {
    sortModel?: GridSortItem[];
    onSortModelChange?: Dispatch<GridSortModel>;
    page?: number;
    setPage?: (page: number) => void;
    pageSize?: number;
    setPageSize?: (pageSize: number) => void;
    pagination?: boolean;
}
export function TableComponent({
    page,
    setPage,
    pageSize = 5,
    setPageSize,
    pagination,
    paginationMode = 'server',
    sortingMode = 'server',
    ...props
}: Props) {
    const paginationOptions: Partial<ComponentProps<typeof DataGrid>> =
        pagination === false
            ? {
                  hideFooter: true,
              }
            : paginationMode === 'server'
              ? {
                    pagination: true as const,
                    paginationMode: 'server' as GridFeatureMode,

                    pageSizeOptions: props.pageSizeOptions ? props.pageSizeOptions : [5, 10, 20],
                    paginationModel: { page: page ? page - 1 : 0, pageSize: pageSize },
                    onPaginationModelChange: (value) => {
                        setPage?.(value?.page + 1);
                        setPageSize?.(value?.pageSize || 5);
                    },
                }
              : { pagination: true as const, paginationMode: 'client' as GridFeatureMode };

    return (
        <>
            {!props.loading && (
                <DataGrid
                    autoHeight
                    sortingMode={sortingMode}
                    sortingOrder={['asc', 'desc']}
                    disableColumnFilter
                    disableColumnMenu
                    slots={{ footer: CustomTableFooter, noRowsOverlay: NoRowsOverlay }}
                    {...paginationOptions}
                    {...props}
                />
            )}
        </>
    );
}

import { Components, Theme } from '@mui/material';
import { grey } from '@mui/material/colors';
import { transparentize } from 'polished';

export function getComponents({ palette, breakpoints, zIndex }: Theme, options?: Components): Components {
    return {
        MuiInputBase: {
            styleOverrides: {
                input: {
                    '&:-webkit-autofill': {
                        transitionDelay: '9999s',
                        transitionProperty: 'background-color, color',
                    },
                },
            },
        },
        MuiBottomNavigation: {
            styleOverrides: {
                root: {
                    borderTop: `solid 1px ${grey['300']}`,
                    position: 'fixed',
                    bottom: 0,
                    left: 0,
                    right: 0,
                },
            },
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    borderBottom: `solid 1px ${grey['300']}`,
                    backgroundColor: 'white',
                    color: palette.primary.main,
                    background: '#FFF',
                    position: 'fixed',
                    marginBottom: '60px',
                    zIndex: zIndex.drawer + 1,
                },
            },
            defaultProps: {
                elevation: 0,
            },
        },
        MuiToolbar: {
            styleOverrides: {
                root: {
                    [breakpoints.up('xs')]: {
                        minHeight: 55,
                    },
                    ['& .app-logo']: {
                        display: 'flex',
                        marginRight: 12,

                        ['& img']: {
                            width: 40,
                        },
                    },
                    '& .MuiTab-root': {
                        minHeight: 55,
                    },
                },
            },
        },
        MuiFormControl: {
            styleOverrides: {
                root: { marginBottom: 24 },
            },
            defaultProps: {
                fullWidth: true,
            },
        },
        MuiTextField: {
            defaultProps: {
                InputLabelProps: {
                    shrink: true,
                },
                fullWidth: true,
            },
        },
        MuiAutocomplete: {
            styleOverrides: {
                root: { '.MuiInputBase-root': { padding: '12px 8px' } },
            },
        },
        MuiInputLabel: {
            defaultProps: {
                shrink: true,
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    paddingLeft: 24,
                    paddingRight: 24,
                    height: 55,
                },
                sizeSmall: {
                    height: 30,
                },
            },
        },
        MuiRadio: {
            styleOverrides: {
                root: {
                    paddingTop: 4,
                    paddingBottom: 4,
                },
            },
        },
        MuiDialog: {
            styleOverrides: {
                paper: {
                    maxHeight: 'fit-content',
                },
            },
        },
        MuiDialogActions: {
            styleOverrides: {
                root: {
                    background: '#FAFAFA',
                    justifyContent: 'flex-start',
                    padding: '16px 24px',
                },
            },
        },
        MuiCard: {
            defaultProps: {
                variant: 'outlined',
            },
            styleOverrides: {
                root: {
                    marginBottom: 12,
                },
            },
        },
        MuiCardHeader: {
            styleOverrides: {
                root: {
                    paddingBottom: 0,
                },
                title: {
                    fontSize: 18,
                    fontWeight: 'bolder',
                },
            },
        },
        MuiCardContent: {
            styleOverrides: {
                root: {
                    '&:last-child': {
                        paddingBottom: '16px',
                    },
                },
            },
        },
        MuiDrawer: {
            styleOverrides: {
                root: {
                    width: '244px',
                    zIndex: 10,
                    flexShrink: 0,

                    '.MuiListItemButton-root': {
                        '&:hover, &:focus': {
                            background: transparentize(0.95, palette.primary.main),
                            color: palette.primary.main,
                        },

                        '&.Mui-selected': {
                            background: transparentize(0.9, palette.primary.main),
                            color: palette.primary.main,
                            '& .MuiListItemText-primary': { fontWeight: 'bold' },
                            '&:hover, &:focus': { background: transparentize(0.9, palette.primary.main) },
                        },
                    },
                },
                paper: {
                    width: '244px',
                    paddingTop: 15,
                    boxSizing: 'border-box',
                    '.MuiList-root': {
                        paddingTop: 0,
                        paddingBottom: 0,
                    },
                },
            },
        },
        MuiTypography: {
            defaultProps: {
                lineHeight: 1.5,
            },
        },
        ...options,
    };
}

export default {
    reports: 'Rapporten',
    startDate: 'Begindatum',
    endDate: 'Einddatum',
    min: 'Minimum',
    max: 'Maximum',
    all: 'Alle',
    export: 'Exporteer rapport',
    filter: 'Filter',
    noRows: 'Geen gegevens gevonden.',
};

import { GridColDef, GridSortItem, GridValidRowModel } from '@mui/x-data-grid';
import { FC, useMemo, useState } from 'react';
import { formatDateString, formatEpoch, formatMonth, TableComponent } from '../../../shared';
import { useReportResult } from '../../hooks';
import { ColumnType, IReport, IReportResult, IRunReportFilter } from '../../models';
import './report.component.scss';
import { allRequiredFiltersFilledInCheck } from '../../utils/all-required-filters-filled-in-check.util';

interface Props {
    report: IReport;
    filters: IRunReportFilter[];
}

const TOTAL_ROW_ID = -1;

export const Report: FC<Props> = ({ report, filters }) => {
    const [sortModel, setSortModel] = useState<GridSortItem[]>();

    const columns = useChartColumns(report);

    const reportFilters = useFilterOutPreviousReportFilters(filters, report);

    const { data: reportResult, isLoading } = useReportResult(
        report.id,
        reportFilters,
        sortModel?.[0] && {
            column: sortModel[0].field,
            order: (sortModel[0].sort?.toUpperCase() as 'ASC') || 'ASC',
        },
        allRequiredFiltersFilledInCheck(report.filters, filters),
    );
    const rows = useChartRows(reportResult);

    return (
        <TableComponent
            getRowId={getRowId}
            getRowClassName={getRowClassName}
            rows={rows}
            columns={columns}
            loading={isLoading}
            pagination={rows.length > 100}
            paginationMode="client"
            sortingMode="server"
            sortModel={sortModel}
            onSortModelChange={setSortModel}
        />
    );
};

function useChartRows(reportResult: IReportResult | undefined) {
    return useMemo(() => {
        const { data = [], totals } = reportResult || {};
        const hasTotals = totals !== undefined && Object.entries(totals)?.length > 0;
        return hasTotals && data.length > 0 ? [...data, hasTotals && { ...totals, rowId: TOTAL_ROW_ID }] : [...data];
    }, [reportResult]);
}

function getRowId(entry: GridValidRowModel): number {
    return entry.rowId;
}

function getRowClassName(entry: GridValidRowModel): string {
    return entry.id === TOTAL_ROW_ID ? 'report-total-row' : '';
}

function useChartColumns(report: IReport | undefined): GridColDef[] {
    return useMemo(
        () =>
            report?.columns
                .filter(({ hidden }) => !hidden)
                .map((column) => ({
                    headerName: column.label,
                    field: column.name,
                    flex: 1,
                    valueFormatter: (value) => formatTableValue(value, column.type),
                })) || [],
        [report?.columns],
    );
}

function formatTableValue(value: unknown, type: ColumnType): unknown {
    switch (type) {
        case ColumnType.DATE:
            return formatDateString(value as string);
        case ColumnType.DATETIME:
            return formatEpoch(value as number);
        case ColumnType.MONTH:
            return formatMonth(value as number);
        default:
            return value;
    }
}

function useFilterOutPreviousReportFilters(filters: IRunReportFilter[], chart: IReport) {
    return useMemo(
        () =>
            filters.filter((filter) =>
                chart.filters.some(
                    (chartFilter) => chartFilter.type === filter.type && chartFilter.column === filter.column,
                ),
            ),
        [filters, chart],
    );
}

import { MenuItem, Stack } from '@mui/material';
import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
    ControlledDatePicker,
    ControlledSelect,
    ISalesQuery,
    Options,
    OptionsToggle,
    SaleStatus,
} from '../../../shared';
import { checkDifferentFilterAndFormValues } from '../../../shared/utils/check-different-filter-and-form-values.util';

interface Props {
    filter: Record<string, any>;
    onChange: (filter: ISalesQuery & { options: Options }) => void;
    showSupplier?: boolean;
}

export const HistoryFilterSales: FC<Props> = ({ filter, onChange }) => {
    const { t } = useTranslation();
    const form = useForm<ISalesQuery>({ mode: 'onChange' });

    const defaultOptions: Options = useMemo(() => {
        return {
            filter: { type: 'title', label: t('filter') },
            updatedDate: { label: t('date'), active: false },
            status: { label: t('status'), active: false },
            hasSupportCase: { label: t('customerType'), active: false },
        };
    }, [t]);

    const filterOptions: Options = useMemo(() => {
        return filter.options || defaultOptions;
    }, [defaultOptions, filter.options]);

    const setOptions = useCallback((options: Options) => onChange({ ...filter, options }), [filter, onChange]);

    const formValues = form.watch();

    useEffect(() => {
        form.reset(filter);
    }, [filter, form]);

    useEffect(() => {
        const { updatedDate, status, hasSupportCase } = filterOptions;
        if (!updatedDate.active) form.setValue('updatedDate', undefined);
        if (!status.active) form.setValue('status', undefined);
        if (!hasSupportCase.active) form.setValue('hasSupportCase', undefined);
    }, [form, filterOptions]);

    useEffect(() => {
        if (!!Object.keys(formValues).length && checkDifferentFilterAndFormValues(formValues, filter)) {
            onChange({ ...formValues, options: filterOptions });
        }
    }, [formValues, filter, onChange, filterOptions]);

    return (
        <FormProvider {...form}>
            <Stack direction="row" alignItems="center" spacing={2}>
                <OptionsToggle options={filterOptions} onChange={setOptions} />
                {filterOptions.updatedDate.active && (
                    <ControlledDatePicker name="updatedDate" label={t('date')} className="filter" />
                )}
                {filterOptions.status.active && (
                    <ControlledSelect name="status" label={t('status')} className="filter">
                        {Object.values(SaleStatus)?.map((status) => (
                            <MenuItem value={status} key={status}>
                                {t(status)}
                            </MenuItem>
                        ))}
                    </ControlledSelect>
                )}
                {filterOptions.hasSupportCase.active && (
                    <ControlledSelect name="hasSupportCase" label={t('customerType')} className="filter">
                        <MenuItem value={'true'} key={'socialCustomer'}>
                            {t('socialCustomer')}
                        </MenuItem>
                        <MenuItem value={'false'} key={'solidarityCustomer'}>
                            {t('solidarityCustomer')}
                        </MenuItem>
                    </ControlledSelect>
                )}
            </Stack>
        </FormProvider>
    );
};

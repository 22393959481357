export default {
    customer: 'Klant',
    card: 'Kaart',
    customerCard: 'Klantenkaart',
    scanCustomerCardTitle: 'Scan klantenkaart',
    searchCustomerCard: 'Zoek op klantencode',
    scanCustomerCard: 'Scan de klantenkaart of geef de klantencode manueel in om een nieuwe verkoop te starten.',
    searchCustomer: 'Zoek een klant',
    searchCustomerInfo:
        'Selecteer een sociale of solidaire klant, uit de lijstweergave, om een nieuwe verkoop te starten',
    searchSolidarityCustomer: 'Zoek een solidaire klant',
    solidarityCustomers: 'Solidaire klanten',
    searchSocialCustomer: 'Zoek een sociale klant',
    socialCustomer: 'Sociale klanten',
    manual: 'manueel',
    search: 'Zoek',
    searchOnName: 'Zoek op naam',
    return: 'Retour',
    returnProduct: 'Retour product',
    manualInput: 'Manueel invoeren',
    priceArticle: 'Prijsartikel',
    searchProduct: 'Typ hier om een product op te zoeken',
    searchCustomerByCode: 'Voer een geldige klanten code in.',
    anonymous: 'Anoniem',
    supportPeriodUntil: 'Hulpperiode tot',
    familySituation: 'Gezinssamenstelling',
    adults: 'Volwassenen',
    children: 'Kinderen',
    ageUnknown: 'Leeftijd onbekend',
    yearsOld: '({{age}}j)',
    MALE: 'M',
    FEMALE: 'V',
    OTHER: 'X',
    postalCode: 'Postcode',
    supportPeriod: 'Hulpperiode',
    lastVisit: 'Laatste bezoek',
    notFound: 'Niet gevonden',
    notActive: 'Niet actief',
    active: 'Actief',
    credit: 'krediet',
    goToDetailSupportCase: 'Dossier details',
    noProductsFound: 'Geen product gevonden',
    startSearchingProducts: 'Typ om een product te zoeken...',
    budgetLabelShop: {
        WEEKLY: 'Resterend week budget',
        BIWEEKLY: 'Resterend tweeweekelijks budget',
        MONTHLY: 'Resterend maand budget',
    },
    solidaritySale: 'Solidaire verkoop',
    solidaritySaleCaption: 'Voor de verkoop aan buurtbewoners.',

    supportCaseNotScanned: 'Er is nog geen klant ingevoerd, scan eerst de klantenkaart',
    productNotFoundTitle: 'Product niet gevonden: {{barcode}}',
    productNotFoundDescription:
        'Ben je zeker dat je de juiste barcode hebt ingevoerd?\nIndien ja, voeg het product toe aan de catalogus via het admin luik.\n\nSluit dit venster voordat je een nieuwe barcode scant',

    shoppingCart: 'Winkelmandje',
    amount: 'Aantal',
    weight: 'Gewicht (kg)',
    price: 'Prijs',
    exceptionalProduct: 'Uitzonderlijk product',
    toPay: 'Te betalen',
    addAmount: 'Toevoegen',
    editAmount: 'Aanpassen',
    emptyShoppingCart: 'Leegmaken',
    abbrevKilogram: ' kg',
    abbrevPiece: ' stuk(s)',
    displayGram: '{{amount}} kg',
    displayPiece_one: '{{count}} stuk',
    displayPiece_other: '{{count}} stuks',
    emptyShoppingCartWarning: 'Bent u zeker dat u het winkelmandje wil leegmaken?',
    noProductsInShoppingCart: 'Er zijn nog geen artikelen in het winkelmandje.',
    removeSaleItem: 'Verwijder product',
    removeSaleItemWarning: 'Bent u zeker dat u dit product uit het winkelmandje wilt verwijderen?',
    customerNotFound: 'Klant niet gevonden, scan een geldige klantenkaart of geef de klantenkaartcode manueel in',

    addManualItem: 'Prijsartikel toevoegen',
    add: 'Toevoegen',
    generalSale: 'Verkoop algemeen',
    unitPrice: 'Eenheid bedrag',

    checkout: 'Afrekenen',
    onHoldSales: 'Klanten in wacht',
    noOnHoldSalesHelpText: 'Momenteel zijn er geen klanten in wacht.',
    onHoldSalesHelpText: 'Klik op "Toon klanten in wacht" om een klant in wacht verder te helpen.',
    noOnHoldSales: 'Geen klanten in wacht',
    showOnHoldSales: 'Toon klanten in wacht',
    onHold: 'In wacht',
    outOfHold: 'Uit wacht halen',
    checkoutMethod: 'Betaalmethode',
    checkoutPayment: 'Betaling',
    cash: 'Contant',
    digital: 'Digitaal',
    total: 'Totaal',
    paid: 'Betaald',
    returnAmount: 'Teruggave',
    paymentSuccessful: 'Betaling geslaagd',
    printTicket: 'Print ticket',
    done: 'Klaar',
    availableCredit: 'Beschikbaar krediet',
    remainingCredit: 'Resterend krediet',
    paidCredit: 'Betaald met krediet',
    supportType: 'Hulptype',
    creditExceeded: 'Krediet overschreden',
    totalPriceExceeded: 'Totaal bedrag overschreden',
    WEEK: 'Wekelijks',
    MONTH: 'Maandelijks',

    startRegisterSession: 'Start kassa',
    countCashRegisterSession: 'Kassa tellen',
    totalInRegister: 'Totaal in kassa',
    totalExpectedInRegister: 'Verwacht totaal',
    bills: 'Briefjes',
    coins: 'Munten',
    amountBills: 'Aantal briefjes van €{{amount}}',
    amountCoins: 'Aantal munten van €{{amount}}',

    start: 'Start',
    stopRegisterSession: 'Kassa afsluiten',
    registerInformation: 'Kassa informatie',
    registerSessionInformationDescription:
        "Klik op 'Kassa afsluiten' om de kassa af te sluiten en de totale opbrengst te berekenen.",
    registerResult: 'Kassa resultaat',
    closeRegister: 'Afsluiten',
    recountStartRegisterSession: 'Kassa telling aanpassen',
    updateStart: 'Kassa telling bijwerken',

    saleQuotumExceededTitle: 'Verkoop quotum overschreden',
    saleQuotumExceededForProduct: 'Het maximale {{period}} verkoop aantal voor "{{product}}" is overschreden.',
    saleQuotumExceeded: {
        WEEKLY: 'wekelijkse',
        MONTHLY: 'maandelijkse',
        SALE: '',
    },
    saleQuotumAllowedAmount: 'Toegelaten aantal',
    saleQuotumReceivedAmount: 'Ingevoerde aantal',
    saleQuotumExceededConfirm:
        'Wilt u als nog deze actie uitvoeren en het verkoop quotum voor deze aankoop overschrijden?',

    budgetExceededTitle: '{{period}} budget overschreden',
    spentBudget: 'Gespendeerd bedrag',
    allowedBudget: 'Toegelaten bedrag',
    budgetExceededConfirm: 'Wilt u als nog deze actie uitvoeren en het toegelaten budget overschrijden?',

    downloadingSaleTicket: 'Kassaticket ophalen',
};
